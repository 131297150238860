import React, { useEffect, useState } from "react"
import FlipNumbers from "react-flip-numbers"
import { graphql, navigate } from "gatsby"
import { API, graphqlOperation, Storage } from "aws-amplify"
import "./project.scss"
import { onUpdateRoom } from "../graphql/subscriptions"
import { listRooms } from "../graphql/queries"
import userCountImage from "../images/userCount.svg"
import desktopImage from "../images/PC_SVG.svg"
import mobileImage from "../images/Phone_SVG.svg"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import BackButton from "../images/backButton.png"
import { Link } from "gatsby"
import SEO from "../components/seo"
import axios from "axios"
import gql from "graphql-tag"
import { print } from "graphql"

import "./project.scss"

const maxPerRoom = 25

const getRoomboard = /* GraphQL */ gql`
  query GetRoomboard($id: ID!) {
    getRoomboard(id: $id) {
      id
      projectId
      title
      description
      sceneID
      maxPerRoom
      createdAt
      updatedAt
      project {
        id
        title
        hubsOrigin
        description
      }
      rooms {
        items {
          id
          projectId
          roomboardId
          title
          description
          hubsID
          userCountLobby
          userCountRoom
          users
          createdAt
          updatedAt
          owner
        }
      }
    }
  }
`

const ImageViewer = props => {
  const [image, setImage] = useState(null)
  useEffect(() => {
    fetchImage()
  }, [])
  async function fetchImage() {
    try {
      const image = await Storage.get("medium" + props.image)
      setImage(image)
    } catch {
      const image = await Storage.get(props.image)
      setImage(image)
    }
  }
  return image && <img src={image}></img>
}

const ProjectPage = props => {
  const projectData = props.data.project.getProject
  const [rooms, setRooms] = useState([])
  const [roomToJoin, setRoomToJoin] = useState(null)

  function setIntervalAndExecute(fn, t) {
    fn()
    return setInterval(fn, t)
  }
  useEffect(() => {
    fetchRooms()
  }, [])
  useEffect(() => {
    const repeatedlyFetchRooms = setInterval(() => {
      fetchRooms()
      // console.log("updated rooms")
    }, 3000)
    // const repeatedlyFetchRooms = setIntervalAndExecute(fetchRooms, 3000)

    // fetch rooms from API
    // fetchRooms()
    // add marker from static graphql query
    props.addMarker([
      {
        position: [projectData.location.lat, projectData.location.lon],
        text: projectData.title,
        id: projectData.id,
        onHubs: projectData.onHubs,
        featured: projectData.featured,
      },
    ])
    // props.flyToZoom(8)
    props.flyToZoom(3)
    props.flyTo([projectData.location.lat, projectData.location.lon])
    return () => {
      // props.flyToZoom([48.309736, 14.284343])
      clearInterval(repeatedlyFetchRooms)
    }
  }, [rooms, roomToJoin])

  async function fetchRooms() {
    try {
      let roomsData
      let fetchedRooms
      if (projectData.mohuniRoomboardId) {
        roomsData = await axios({
          url: process.env.NEXT_PUBLIC_MOHUNI_API_URL,
          method: "post",
          headers: {
            "x-api-key": process.env.NEXT_PUBLIC_MOHUNI_API_KEY,
          },
          data: {
            query: print(getRoomboard),
            variables: { id: projectData.mohuniRoomboardId },
          },
        })
        fetchedRooms = roomsData.data.data.getRoomboard.rooms.items.sort(
          function (a, b) {
            return new Date(a.createdAt) - new Date(b.createdAt)
          }
        )
      }
      // else {
      //   roomsData = await API.graphql(
      //     graphqlOperation(listRooms, {
      //       filter: { projectID: { eq: projectData.id } },
      //       limit: 1000,
      //     })
      //   )
      //   fetchedRooms = roomsData.data.listRooms.items.sort(function (a, b) {
      //     return new Date(a.createdAt) - new Date(b.createdAt)
      //   })
      // }
      // console.log("roomsData:", fetchedRooms)

      fetchedRooms.forEach(room => {
        if (room.userCountRoom + room.userCountLobby < maxPerRoom) {
          room.full = false
        } else {
          room.full = true
        }
        room.userCountTotal = room.userCountRoom + room.userCountLobby
        delete room.createdAt
        delete room.updatedAt
        delete room.project
        delete room.projectID
      })
      // console.log("rooms first: ", fetchedRooms)
      setRooms(fetchedRooms)
      updateRoomToJoin(fetchedRooms)
    } catch (err) {
      console.log("error fetching projects: ", err)
    }
  }

  function updateRoomToJoin(inputRooms) {
    const sortedRooms = inputRooms.sort(function (a, b) {
      return (
        b.userCountRoom +
        b.userCountLobby -
        (a.userCountRoom + a.userCountLobby)
      )
    })
    const availableRooms = sortedRooms.filter(room => !room.full)
    var userCanJoin = true
    if (availableRooms.length > 0) {
      userCanJoin = true
      setRoomToJoin(availableRooms[0])
      console.log("room to join: ", availableRooms[0])
      // navigate("https://keplersgardens.net/" + roomToJoin.hubsId)
    } else {
      userCanJoin = false
      setRoomToJoin(null)
    }
    // console.log("room to join: ", availableRooms[0])
  }
  function goBack() {
    if (typeof window !== "undefined") {
      window.history.back()
    }
  }

  return (
    <div className="projectPage">
      <SEO title={projectData.title} />
      <div className="padded">
        <img
          className="back-button"
          src={BackButton}
          onClick={() => goBack()}
        ></img>
        <h1>{projectData.title}</h1>
        <h2>{projectData.subtitle}</h2>
      </div>
      <ImageViewer image={projectData.image}></ImageViewer>
      <div key="first-padded" className="padded">
        <p className="description">{projectData.description}</p>
        {projectData.onHubs &&
          projectData.id !== "d2a3e521-5d03-42fd-b0fb-dd5329d7b0ef" && (
            <p className="first-time">
              First time in Hubs?{" "}
              <Link to="/project/d2a3e521-5d03-42fd-b0fb-dd5329d7b0ef">
                Start here.
              </Link>
            </p>
          )}
        <div className="icons-and-join">
          <div className="mobileFriendly">
            <img src={desktopImage} title="Best viewed on dekstop"></img>
            {projectData.mobileFriendly && (
              <img src={mobileImage} title="Mobile-friendly"></img>
            )}
          </div>
          {projectData.onHubs && (
            <div>
              {roomToJoin !== null ? (
                <OverlayTrigger
                  key={"quick-join"}
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      Automatically finds the best room to join.
                    </Tooltip>
                  }
                >
                  <button
                    className="submit"
                    onClick={() => {
                      navigate("https://hubs.mozilla.com/" + roomToJoin.hubsID)
                    }}
                  >
                    Quick Join
                  </button>
                </OverlayTrigger>
              ) : // <OverlayTrigger
              //   key={"no-quick-join"}
              //   placement={"top"}
              //   overlay={
              //     <Tooltip id={`tooltip-top`}>Check back soon.</Tooltip>
              //   }
              // >
              //   <button>All rooms full</button>
              // </OverlayTrigger>
              null}
            </div>
          )}
        </div>
        <div className="rooms">
          {rooms.length > 0 &&
            rooms.map((room, index) => (
              <div className="room" key={room.id}>
                <div>Room {index + 1}</div>
                <div className="flip-number">
                  <img src={userCountImage} className="user-count-image"></img>

                  {room.userCountTotal !== undefined && (
                    <span>
                      <FlipNumbers
                        height={11}
                        width={25}
                        color="black"
                        background="transparent"
                        play
                        //   perspective={0}
                        numbers={`${room.userCountTotal}`}
                        numberStyle={{
                          fontFamily: "IBM Plex Mono",
                          fontWeight: "normal",
                          fontSize: "12px",
                        }}
                      />
                    </span>
                  )}
                  {/* <span>{room.userCountTotal + " "}</span> */}
                  <span style={{ fontWeight: "normal" }}>/ {maxPerRoom}</span>
                </div>
                <div>
                  {room.full ? (
                    <button className="cancel" disabled={true}>
                      Full
                    </button>
                  ) : (
                    <button
                      className="submit"
                      onClick={() => {
                        navigate("https://hubs.mozilla.com/" + room.hubsID)
                      }}
                    >
                      Join
                    </button>
                  )}
                </div>
              </div>
            ))}
        </div>
        <div className="artist">
          {/* <h2>{projectData.ownerName}</h2> */}
          <div>
            <span>
              <a href={projectData.website}>Website</a>
            </span>
            {/* <span>{" ・ "}</span>
            <span>
              <a href={"mailto:" + projectData.email}>Email</a>
            </span> */}
          </div>
        </div>
      </div>
    </div>
  )
}

export const query = graphql`
  query($id: ID!) {
    project {
      getProject(id: $id) {
        id
        email
        mohuniRoomboardId
        featured
        mobileFriendly
        description
        image
        location {
          lat
          lon
        }
        onHubs
        ownerBio
        ownerName
        rooms {
          items {
            hubsId
            userCountLobby
            userCountRoom
            id
          }
        }
        subtitle
        tags {
          items {
            tag {
              id
              name
              mainTag {
                id
                name
              }
            }
          }
        }
        title
        website
      }
    }
  }
`

export default ProjectPage
